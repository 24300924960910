import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CTA = makeShortcode("CTA");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`On-Chain Governance`}</h1>
    <h2>{`Governance Polls`}</h2>
    <p>{`Governance Polls occur on-chain and can be accessed through the Maker Foundation's `}<a parentName="p" {...{
        "href": "https://vote.makerdao.com/polling"
      }}>{`Voting Portal`}</a>{`.`}</p>
    <p>{`Polls use `}<a parentName="p" {...{
        "href": "https://ballotpedia.org/Ranked-choice_voting_(RCV)"
      }}>{`instant run-off`}</a>{`, so you can select multiple options and ranked-choices will be prioritized.`}</p>
    <CTA mdxType="CTA">
      <p>{`Governance Polls measure the sentiment of MKR voters, and are used to:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Determine governance and DAO processes outside the technical layer of the Maker Protocol.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Form consensus on important community goals and targets.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Measure sentiment on potential Executive Vote proposals.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Ratify governance proposals originating from the MakerDAO forum signal threads.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Determine which values certain system parameters should be set to before those values are then confirmed in an executive vote.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Ratify risk parameters for new collateral types as presented by Risk Teams.`}</p>
        </li>
      </ul>
    </CTA>
    <h1>{`Executive Votes`}</h1>
    <p>{`Executive Votes occur on-chain and can be accessed through the Maker Foundation's `}<a parentName="p" {...{
        "href": "https://vote.makerdao.com/polling"
      }}>{`Voting Portal`}</a>{`.`}</p>
    <p>{`Executive Votes "execute" technical changes to the Maker Protocol. When active, each Executive Vote has a proposed set of changes being made on the Maker Protocol's smart-contracts.`}</p>
    <CTA mdxType="CTA">
      <p>{`Unlike the other types of votes, Executive Votes use a 'Continuous Approval Voting' model.`}</p>
      <p>{`Executive Votes can be used to:`}</p>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`Add or remove collateral types.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Add or remove Vault types.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Adjust global system parameters.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Adjust Vault-specific parameters.`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Replace modular smart contracts.`}</p>
        </li>
      </ul>
    </CTA>
    <p>{`Anyone can create an on-chain Executive Vote using the MakerDAO governance contracts, however, there is no non-technical UI available to do this.`}</p>
    <p>{`Users can create proposals, also known as "`}<a parentName="p" {...{
        "href": "https://docs.makerdao.com/smart-contract-modules/governance-module/chief-detailed-documentation"
      }}>{`Slates`}</a>{`," through this `}<a parentName="p" {...{
        "href": "https://chief.makerdao.com/"
      }}>{`experimental portal`}</a>{`, or by interacting directly with the smart contracts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      